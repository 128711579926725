import React, { useState } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Stack,
  Heading,
  Input,
  Textarea,
  Checkbox,
  Divider,
  FormControl, FormLabel
} from '@chakra-ui/react';
import Layout from '../components/Layout';

const ConfigForm = ({config}) => {
  const [formData, setFormData] = useState({
    siteTitle: '',
    api: '',
    website: '',
    email: [],
    primaryColor: '',
    apply: '',
    phoneNumber: '',
    buttonColor: '',
    logoUrl: '',
    faviconUrl: '',
    aboutUs: {title: '', description: ''},
    contactUs:{address: '', email: '', phoneNumber: '', workingHours: ''},
    typography: {bodyFont: '', headingFont: '', fontSizeBase: '', lineHeightBase: ''},
    admissionSession: '',
    remita: {
      remitaLive: false,
      remitaTest: false,
      serviceTypeId: '',
      apiKey: '',
      merchantId: '',
      publicKey: '',
      secretKey: '',
      username: '',
      baseUrl: '',
    },
    seo: {
      metaDescription: '',
      metaKeywords: '',
      metaAuthor: '',
      ogImage: '',
    },
    slides: {
      slideTitle: '',
      slideImageUrl: '',
      slideLink: '',
    },
    socialLinks: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
    },
    transcript: {
      download: '',
      email: '',
      postal:'',
    },
    maintenanceMode: '',
    footer: { text: '', grid: [{ title: '', aboutText:'' }]},
    menu: [{name: '', href: ''}],
  });

  // Handle input change
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  const handleAddMenuItem = () => {
    setFormData((prevData) => ({
      ...prevData, // Retain all other fields
      menu: [...prevData.menu, { title: '', link: '' }], // Append a new menu item
    }));
  };
  
  const handleChangeMenuItem = (index, field, value) => {
    setFormData((prevData) => {
      const updatedMenuItems = [...prevData.menu];
      updatedMenuItems[index][field] = value;
      return {
        ...prevData, // Retain all other fields
        menu: updatedMenuItems, // Update only the menu field
      };
    });
  };
  // Slide Handlers
  const handleAddSlide = () => {
    setFormData((prevData) => ({
      ...prevData,
      slides: [...prevData.slides, { title: '', imageUrl: '', description: '' }],
    }));
  };

  const handleChangeSlide = (index, field, value) => {
    setFormData((prevData) => {
      const updatedSlides = [...prevData.slides];
      updatedSlides[index][field] = value;
      return {
        ...prevData,
        slides: updatedSlides,
      };
    });
  };
  

  return (
    <Layout config={config}>
    <Tabs isLazy>
      <TabList>
        <Tab>General</Tab>
        <Tab>Remita</Tab>
        <Tab>SEO</Tab>
        <Tab>Slides</Tab>
        <Tab>Social Links</Tab>
      </TabList>

      <TabPanels>
        {/* General Tab */}
        <TabPanel>
          <Stack spacing={4}>
            <Heading size="md">General Settings</Heading>
            <Divider />
            
            <FormControl id="primaryColor" mb={4}>
          <FormLabel>Site Title </FormLabel>
            <Input
              name="siteTitle"
              placeholder="Site Title"
              value={formData.siteTitle}
              onChange={handleChange}
            />
            </FormControl>
            
            <FormControl mb={4}>
          <FormLabel>Website </FormLabel>
            <Input
              name="website"
              placeholder="Site URL"
              value={formData.website}
              onChange={handleChange}
            />
            </FormControl>
            
            <FormControl  mb={4}>
          <FormLabel>API</FormLabel>
              <Input
              name="api"
              placeholder="API"
              value={formData.api}
              onChange={handleChange}
            />
        </FormControl>
               <FormControl id="primaryColor" mb={4}>
          <FormLabel>Primary Color</FormLabel>
          <Input type="text" name="primaryColor"   onChange={handleChange} />
        </FormControl>

        {/* Email */}
        <FormControl id="email" mb={4}>
          <FormLabel>Email Addresses</FormLabel>
          <Textarea
            placeholder="info@dlc.edu.ng, dr@dlc.edu.ng, record@dlc.edu.ng"
            name="email"
            rows={3}
            onChange={handleChange}
          />
        </FormControl>

        {/* Phone Number */}
        <FormControl id="phoneNumber" mb={4}>
          <FormLabel>Phone Numbers</FormLabel>
          <Textarea
            placeholder="07037637583, 07082807899, 07052349309"
            name="phoneNumber"
            rows={3}
          />
        </FormControl>

        {/* Button Color */}
        <FormControl id="buttonColor" mb={4}>
          <FormLabel>Button Color</FormLabel>
          <Input type="text" name="buttonColor"   onChange={handleChange} />
        </FormControl>
        <FormControl id="aboutUsTitle" mb={4}>
          <FormLabel>About Us Title</FormLabel>
          <Input
            type="text"
            name="aboutUsTitle"
            value={formData.aboutUsTitle}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="aboutUsDescription" mb={4}>
          <FormLabel>About Us Description</FormLabel>
          <Textarea
            name="aboutUsDescription"
            value={formData.aboutUsDescription}
            onChange={handleChange}
            rows={4}
          />
        </FormControl>

        {/* Contact Us */}
        <FormControl id="contactUsAddress" mb={4}>
          <FormLabel>Contact Us Address</FormLabel>
          <Input
            type="text"
            name="contactUsAddress"
            value={formData.contactUsAddress}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="contactUsEmail" mb={4}>
          <FormLabel>Contact Us Email</FormLabel>
          <Input
            type="email"
            name="contactUsEmail"
            value={formData.contactUsEmail}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="contactUsPhoneNumber" mb={4}>
          <FormLabel>Contact Us Phone Number</FormLabel>
          <Input
            type="text"
            name="contactUsPhoneNumber"
            value={formData.contactUsPhoneNumber}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="contactUsWorkingHours" mb={4}>
          <FormLabel>Working Hours</FormLabel>
          <Input
            type="text"
            name="contactUsWorkingHours"
            value={formData.contactUsWorkingHours}
            onChange={handleChange}
          />
        </FormControl>

        {/* Typography */}
        <FormControl id="typographyBodyFont" mb={4}>
          <FormLabel>Body Font</FormLabel>
          <Input
            type="text"
            name="typographyBodyFont"
            value={formData.typographyBodyFont}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="typographyHeadingFont" mb={4}>
          <FormLabel>Heading Font</FormLabel>
          <Input
            type="text"
            name="typographyHeadingFont"
            value={formData.typographyHeadingFont}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="typographyFontSizeBase" mb={4}>
          <FormLabel>Font Size (Base)</FormLabel>
          <Input
            type="text"
            name="typographyFontSizeBase"
            value={formData.typographyFontSizeBase}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="typographyLineHeightBase" mb={4}>
          <FormLabel>Line Height (Base)</FormLabel>
          <Input
            type="text"
            name="typographyLineHeightBase"
            value={formData.typographyLineHeightBase}
            onChange={handleChange}
          />
        </FormControl>

        {/* Footer Background */}
        <FormControl id="footerBg" mb={4}>
          <FormLabel>Footer Background Image</FormLabel>
          <Input
            type="text"
            name="footerBg"
            value={formData.footerBg}
            onChange={handleChange}
          />
        </FormControl>

        {/* Acceptance Fee */}
        <FormControl id="acceptanceFee" mb={4}>
          <FormLabel>Acceptance Fee</FormLabel>
          <Input
            type="number"
            name="acceptanceFee"
            value={formData.acceptanceFee}
            onChange={handleChange}
          />
        </FormControl>

        {/* Transcript */}
        <FormControl id="transcriptDownload" mb={4}>
          <FormLabel>Transcript Download Fee</FormLabel>
          <Input
            type="number"
            name="transcriptDownload"
            value={formData.transcriptDownload}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="transcriptEmail" mb={4}>
          <FormLabel>Transcript Email</FormLabel>
          <Input
            type="email"
            name="transcriptEmail"
            value={formData.transcriptEmail}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="transcriptPostal" mb={4}>
          <FormLabel>Transcript Postal Address</FormLabel>
          <Input
            type="text"
            name="transcriptPostal"
            value={formData.transcriptPostal}
            onChange={handleChange}
          />
        </FormControl>
          </Stack>
        </TabPanel>

        {/* Remita Tab */}
        <TabPanel>
          <Stack spacing={4}>
            <Heading size="md">Remita Settings</Heading>
            <Divider />
            <Checkbox
              name="remitaLive"
              isChecked={formData.remita.remitaLive}
              onChange={handleChange}
            >
              Live
            </Checkbox>
            <Checkbox
              name="remitaTest"
              isChecked={formData.remita.remitaTest}
              onChange={handleChange}
            >
              Test
            </Checkbox>
            <Input
              name="serviceTypeId"
              placeholder="Service Type ID"
              value={formData.remita.serviceTypeId}
              onChange={handleChange}
            />
            <Input
              name="apiKey"
              placeholder="API Key"
              value={formData.remita.apiKey}
              onChange={handleChange}
            />
            <Input
              name="merchantId"
              placeholder="Merchant ID"
              value={formData.remita.merchantId}
              onChange={handleChange}
            />
            <Input
              name="publicKey"
              placeholder="Public Key"
              value={formData.remita.publicKey}
              onChange={handleChange}
            />
            <Input
              name="secretKey"
              placeholder="Secret Key"
              value={formData.remita.secretKey}
              onChange={handleChange}
            />
            <Input
              name="username"
              placeholder="Username"
              value={formData.remita.username}
              onChange={handleChange}
            />
            <Input
              name="baseUrl"
              placeholder="Base URL"
              value={formData.remita.baseUrl}
              onChange={handleChange}
            />
          </Stack>
        </TabPanel>

        {/* SEO Tab */}
        <TabPanel>
          <Stack spacing={4}>
            <Heading size="md">SEO Settings</Heading>
            <Divider />
            <Textarea
              name="metaDescription"
              placeholder="Meta Description"
              value={formData.seo.metaDescription}
              onChange={handleChange}
            />
            <Textarea
              name="metaKeywords"
              placeholder="Meta Keywords"
              value={formData.seo.metaKeywords}
              onChange={handleChange}
            />
            <Input
              name="metaAuthor"
              placeholder="Meta Author"
              value={formData.seo.metaAuthor}
              onChange={handleChange}
            />
            <Input
              name="ogImage"
              placeholder="OG Image URL"
              value={formData.seo.ogImage}
              onChange={handleChange}
            />
          </Stack>
        </TabPanel>

        {/* Slides Tab */}
        <TabPanel>
          <Stack spacing={4}>
            <Heading size="md">Slides Settings</Heading>
            <Divider />
            <Input
              name="slideTitle"
              placeholder="Slide Title"
              value={formData.slides.slideTitle}
              onChange={handleChange}
            />
            <Input
              name="slideImageUrl"
              placeholder="Slide Image URL"
              value={formData.slides.slideImageUrl}
              onChange={handleChange}
            />
            <Input
              name="slideLink"
              placeholder="Slide Link"
              value={formData.slides.slideLink}
              onChange={handleChange}
            />
          </Stack>
        </TabPanel>

        {/* Social Links Tab */}
        <TabPanel>
          <Stack spacing={4}>
            <Heading size="md">Social Links Settings</Heading>
            <Divider />
            <Input
              name="facebook"
              placeholder="Facebook URL"
              value={formData.socialLinks.facebook}
              onChange={handleChange}
            />
            <Input
              name="twitter"
              placeholder="Twitter URL"
              value={formData.socialLinks.twitter}
              onChange={handleChange}
            />
            <Input
              name="instagram"
              placeholder="Instagram URL"
              value={formData.socialLinks.instagram}
              onChange={handleChange}
            />
            <Input
              name="linkedin"
              placeholder="LinkedIn URL"
              value={formData.socialLinks.linkedin}
              onChange={handleChange}
            />
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
    </Layout>
  );
};

export default ConfigForm;
