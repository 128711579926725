import React, { useState, useEffect } from "react";
import {
  Button,
  FormLabel,
  Grid,
  GridItem,
  VStack,
  Box,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import DynamicField from "./DynamicField";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

const DynamicSection = ({ sections, setSections, fieldsConfig, sectionTitle, handleDynamicChange, removeSection, value }) => {
  const [sectionValues, setSectionValues] = useState([]);

  // Prevent multiple calls to addSection
  const hasAddedSectionRef = React.useRef(false);

  useEffect(() => {
    if (!sections && !hasAddedSectionRef.current) {
      addSection();
      hasAddedSectionRef.current = true; // Mark that the section has been added
    }
  }, [sections]);

  // Update the section values when a field changes
  const handleFieldChange = (sectionIndex, e) => {
    const { name, value, type, checked } = e.target;

    // Update the section values directly
    setSectionValues((prevValues) => {
      const updatedValues = [...prevValues];  // Copy previous values
      
      // Ensure the section exists in the array
      if (!updatedValues[sectionIndex]) {
        updatedValues[sectionIndex] = {};  // Initialize section if not already present
      }

      // Update the specific field in the section
      updatedValues[sectionIndex][name] = type === "checkbox" ? checked : value;

      return updatedValues;
    });
    

    // Call handleDynamicChange with updated sectionValues (pass the index but don't add it to sectionValues)
    handleDynamicChange(sectionTitle, sectionValues, sectionIndex);
  };

  // Function to add an empty section with default field values
  const addSection = () => {
    const emptySection = fieldsConfig.reduce((acc, field) => {
      if (field.type === "field") {
        acc[field.name] = field.inputType === "checkbox" ? false : "";
      } else if (field.type === "section") {
        acc[field.name] = []; // Nested sections
      }
      return acc;
    }, {});

    // Add the empty section to the existing sections (append to the array)
    setSections((prevSections) => [...prevSections, emptySection]);
  };


  const shouldRenderField = (field, sectionValues, sectionIndex) => {
    if (!field.condition) return true;
    console.log(field.condition, sectionIndex)
    const [key, value] = Object.entries(field.condition)[0];
    const result = sectionValues[sectionIndex]?.[key] === value;
    console.log(`Evaluating field: ${field.name}, Condition: ${key}=${value}, Result: ${result}`);
    return result;
  };
  
  // Function to render a specific field based on conditions
  const renderFields = (field, sectionIndex) => {
    if (field.type === "field" && shouldRenderField(field, sectionValues, sectionIndex)) {
      let res = value ? value[sectionIndex][field.name] : ""
      return (
        <DynamicField
          key={field.name}
          label={field.label}
          name={field.name}
          type={field.inputType}
          handleChange={(e) => handleFieldChange(sectionIndex, e)}
          options={field.options}
          value={res ? res : ""}
          isRequired={field.isRequired}
        />
      );
    }

    // If the field type is "section", handle rendering the section fields
    if (field.type === "section" && shouldRenderField(field, sectionValues, sectionIndex)) {
      return (
        <Box key={field.name} my={4} p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
        <FormLabel fontSize="md" fontWeight="bold">
          {field.label}
          </FormLabel>
          
        <Grid templateColumns="repeat(2, 1fr)">
        {field?.fields?.map((ol, ind)=>(
        <DynamicField
        key={ol.name}
        label={ol.label}
        name={ol.name}
        type={ol.inputType}
        handleChange={(e) => handleFieldChange(ind, e)}
        options={ol.options}
        isRequired={ol.isRequired}
      />))}</Grid>
      </Box>
      );
    }
  };

  // Render the fields based on sections or fieldsConfig
  const renderSectionContent = (sectionIndex) => {
    return (
      <Box key={sectionIndex} mb={4}>
        <HStack justify="space-between">
          <Grid templateColumns="repeat(3, 1fr)">
            {fieldsConfig.map((field) => renderFields(field, sectionIndex))}
          </Grid>
        </HStack>
        {sectionIndex !== 0 && (
          <IconButton
            aria-label="Remove Section"
            icon={<DeleteIcon />}
            colorScheme="red"
            onClick={() => removeSection(sectionTitle, sectionIndex)}
          />
        )}
      </Box>
    );
  };

  return (
    <GridItem colSpan={3} width={"100%"}>
     
      <HStack spacing={6} align="stretch">
      <FormLabel fontSize="sm" fontWeight="bold" mb={4}>
        {sectionTitle}
      </FormLabel>
        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
          {value ? value?.map((section, sectionIndex) => renderSectionContent(sectionIndex)):
          sections?.map((section, sectionIndex) => renderSectionContent(sectionIndex))
          }
        </Box>
      </HStack>
      <Button
        mt={4}
        fontSize="sm"
        colorScheme="blue"
        onClick={addSection}
        leftIcon={<AddIcon />}
      >
        Add Section
      </Button>
    </GridItem>
  );
};

export default DynamicSection;
